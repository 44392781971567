export default function createDots(wrapper, slider, classname) {
  let wrap = document.querySelector(wrapper);
  wrap.classList.add("slider-dots");
  let l = slider._c.Html.slides.length;

  for (let i = 0; i < l; i++) {
    let dot = document.createElement("div");
    dot.classList.add("dot");
    dot.classList.add(classname);
    if (i == 0) {
      dot.classList.add("active");
    }
    dot.addEventListener("click", function () {
      slider.go(`=${i}`);
    });
    wrap.appendChild(dot);
  }
}
