import Glide from "@glidejs/glide";
import addActiveClass from "./utilites/utility";
import createDots from "./utilites/createDots";

document.addEventListener("DOMContentLoaded", () => {
  try {
    if (document.querySelectorAll(".case-slider .glide__slide").length) {
      const case_slider_activ = document.querySelector(
        ".case-slider__nav--active"
      );
      const cases_slider = new Glide(".case-slider", {
        type: "carousel",
        startAt: 0,
        perView: 4,
        autoplay: 2500,
        gap: 40,
        peek: {
          before: 150,
          after: 150,
        },
        breakpoints: {
          2000: {
            perView: 3,
            peek: {
              before: 100,
              after: 100,
            },
          },
          1200: {
            perView: 2,
            gap: 20,
            peek: {
              before: 70,
              after: 70,
            },
          },
          767: {
            perView: 1,
            gap: 30,
            peek: {
              before: 50,
              after: 50,
            },
          },

          576: {
            perView: 1,
            gap: 10,
            peek: {
              before: 40,
              after: 40,
            },
          },
        },
      });

      let case_slider_length = document
        .querySelector(".case-slider")
        .querySelectorAll(".case-slider__item").length;
      cases_slider.on("build.before", () => {
        case_slider_activ.style.width = 100 / case_slider_length + "%";
      });

      cases_slider.on("run", () => {
        document.querySelector(".case-slider__counter").textContent =
          cases_slider.index + 1;
        case_slider_activ.style.left =
          (100 / case_slider_length) * cases_slider.index + "%";
      });

      cases_slider.mount();

      document.querySelector(".cases-prev").addEventListener("click", () => {
        cases_slider.go("<");
      });
      document.querySelector(".cases-next").addEventListener("click", () => {
        cases_slider.go(">");
      });
    }
  } catch (e) {
    console.error(e);
  }

  // feedback form
  try {
    document
      .querySelector(".feedback__submit")
      .addEventListener("click", () => {
        let form_data = {};
        document.querySelectorAll(".feedback__input").forEach((el) => {
          if(!el.value) return 
          
          form_data[el.name] = el.value;
        });

        if (Object.values(form_data).length) {
          localStorage.setItem('form_data', JSON.stringify(form_data));
        }

        location.href = window.location + '/hire'
      });

      let phone_input = document.querySelector('.feedback__input[name=phone]')
      if (phone_input) {
        phone_input.addEventListener('input', (e) => {
          e.target.value = e.target.value.replace(/[^\d/+]/g, '')
        })
        phone_input.addEventListener('focus', (e) => {
          if (!e.target.value.trim()) e.target.value = '+'
        })
        phone_input.addEventListener('blur', (e) => {
          if (e.target.value.trim() == '+') e.target.value = ''
        })
      }
      
  } catch (e) {
    console.log(e);
  }

  // benefits
  try {
    if (document.querySelectorAll(".benefits-slider .glide__slide").length) {
      const benefits_slider = new Glide(".benefits-slider", {
        type: "carousel",
        startAt: 0,
        perView: 4,
        gap: 30,
        peek: {
          before: 0,
          after: 25,
        },

        breakpoints: {
          1799: {
            perView: 3,
          },
          1399: {
            perView: 2,
          },
          767: {
            perView: 1,
            gap: 10,
            peek: {
              before: 0,
              after: 55,
            },
          },
        },
      }).mount();

      document.querySelector(".benefits-prev").addEventListener("click", () => {
        benefits_slider.go("<");
      });
      document.querySelector(".benefits-next").addEventListener("click", () => {
        benefits_slider.go(">");
      });
    }
  } catch (e) {
    console.error(e);
  }

  // testimonials
  try {
    if (
      document.querySelectorAll(".testimonials-slider .glide__slide").length
    ) {
      const testimonials_slider = new Glide(".testimonials-slider", {
        type: "slider",
        startAt: 0,
        perView: 1,
      });

      let testimonials_images = document.querySelectorAll(".testimonials-img");
      let testimonials_images_array =
        Array.prototype.slice.call(testimonials_images);
      let testimonials_bg = document.querySelector(".testimonials-bg");

      testimonials_images_array.forEach((img, i) => {
        img.addEventListener("click", () => {
          testimonials_slider.go(`=${i}`);
        });
      });

      testimonials_slider.on("run", () => {
        let n = testimonials_slider.index;

        testimonials_images_array.forEach(function (item, i) {
          item.className = item.className.replace(/(order\-)\d+\s*/g, "");
        });

        let lastPostOrder = 0;
        let prevGroup = testimonials_images_array.filter((elem, i) => {
          if (i < n) {
            return elem;
          }
        });
        let nextGroup = testimonials_images_array.filter((elem, i) => {
          if (i > n) {
            return elem;
          }
        });

        testimonials_images_array[n].classList.add("order-1");
        lastPostOrder = 1;

        if (nextGroup.length > 0) {
          nextGroup.forEach(function (el, i) {
            lastPostOrder += 1;
            el.classList.add("order-" + lastPostOrder);
          });
        }
        if (prevGroup.length > 0) {
          prevGroup.forEach(function (el, i) {
            lastPostOrder += 1;
            el.classList.add("order-" + lastPostOrder);
          });
        }

        // dots
        addActiveClass(
          document.querySelectorAll(".testimonials-dot"),
          testimonials_slider.index + 1
        );
      });

      testimonials_slider.mount();

      createDots(
        ".testimonials-slider-dots-wrap",
        testimonials_slider,
        "testimonials-dot"
      );
    }
  } catch (e) {
    console.error(e);
  }

  try {
    let tabs = document.querySelectorAll(".tabs-block__item"),
      tabs_desc = document
        .querySelector(".tabs-block__nav")
        .querySelectorAll(".tabs-block__item"),
      tabs_mob = document.querySelectorAll(".tabs-block-mob__item"),
      tabs_content_mob = document.querySelectorAll(".tab-nav-mob"),
      tabs_content = document.querySelectorAll(
        ".tabs-block__content-item-wrap"
      ),
      tabs_counter = 1,
      tabs_slider = document.querySelector(".tabs-block__nav-slider"),
      tabs_arrow = document.querySelector(".tabs-block__nav-arrow"),
      tabs_content_slider = document.querySelector(
        ".tabs-block__content-nav--active"
      ),
      tabs_content_count = document.querySelector(
        ".tabs-block__content-nav-count"
      ),
      tabs_btn_prev = document.querySelector(".tabs-block__content--prev"),
      tabs_btn_next = document.querySelector(".tabs-block__content--next");

    const openActiveTab = (tab) => {
      addActiveClass(tabs_desc, tab);
      addActiveClass(tabs_mob, tab);
      addActiveClass(tabs_content_mob, tab);

      tabs_content.forEach((item) => {
        item.classList.remove("active");
      });

      tabs_slider.style.top = 25 + 70 * (tab - 1) + "px";
      tabs_arrow.style.top = 75 + 70 * (tab - 1) + "px";
      tabs_content_slider.style.left = 25 * (tab - 1) + "%";
      document.getElementById(tab).classList.add("active");
      tabs_content_count.innerHTML = tab;

      let margin = 0;

        tabs_mob.forEach((el, i) => {
          if (i > tab - 2) return;
          margin += el.offsetWidth+30;
        });

        document.querySelector(".tabs-block-mob").scroll({
          left: margin,
          top: 0,
          behavior: 'smooth'
      })
    };

    tabs_btn_prev.onclick = () => {
      tabs_counter--;
      if (tabs_counter <= 0) {
        tabs_counter = 4;
      }
      openActiveTab(tabs_counter);
    };
    tabs_btn_next.onclick = () => {
      tabs_counter++;
      if (tabs_counter > 4) {
        tabs_counter = 1;
      }
      openActiveTab(tabs_counter);
    };

    tabs.forEach((el, i) => {
      el.addEventListener("click", () => {
        let tab = +el.getAttribute("data-tab");
        tabs_counter = tab;
        openActiveTab(tab);
      });
    });

    // tabs swipe

    const offset = 60;
    let xDown, yDown;

    document
      .querySelector(".tabs-block__content")
      .addEventListener("touchstart", (e) => {
        const firstTouch = getTouch(e);
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
      });

    document
      .querySelector(".tabs-block__content")
      .addEventListener("touchend", (e) => {
        if (!xDown || !yDown) {
          return;
        }

        const { clientX: xUp, clientY: yUp } = getTouch(e);
        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;
        const xDiffAbs = Math.abs(xDown - xUp);
        const yDiffAbs = Math.abs(yDown - yUp);

        // at least <offset> are a swipe
        if (Math.max(xDiffAbs, yDiffAbs) < offset) {
          return;
        }

        if (xDiffAbs > yDiffAbs) {
          if (xDiff > 0) {
            tabs_counter++;
            if (tabs_counter > 4) {
              tabs_counter = 1;
            }
            openActiveTab(tabs_counter);
          } else {
            tabs_counter--;
            if (tabs_counter <= 0) {
              tabs_counter = 4;
            }
            openActiveTab(tabs_counter);
          }
        } else {
          return;
        }
      });

    function getTouch(e) {
      return e.changedTouches[0];
    }
  } catch (e) {
    console.error(e);
  }

  // clients slider
  try {
    if (document.querySelectorAll(".clients-slider .glide__slide").length) {
      const clients_slider = new Glide(".clients-slider", {
        type: "carousel",
        startAt: 0,
        perView: 5,
        gap: 30,
        peek: {
          before: 0,
          after: 25,
        },

        breakpoints: {
          1399: {
            perView: 4,
          },
          991: {
            perView: 3,
          },
          767: {
            perView: 2,
          },
        },
      });
      clients_slider.mount();

      document.querySelectorAll(".clients-prev").forEach((el) => {
        el.addEventListener("click", () => {
          clients_slider.go("<");
        });
      });
      document.querySelectorAll(".clients-next").forEach((el) => {
        el.addEventListener("click", () => {
          clients_slider.go(">");
        });
      });
    }
  } catch (e) {
    console.error(e);
  }

  /////////////////////////
  try {
    const stages = document.querySelector(".stages");
    const stages_next = document.querySelector(".stages-next");
    const stages_prev = document.querySelector(".stages-prev");
    const $rocket = document.querySelector(".rocket-svg");
    const dotsArr = document.querySelectorAll(".rocket-point");
    let rocket_pos = [300, 740, 1230, 1610, 2040, 2470, 2890, 3325];
    let step = 2;
    let hideWidth =
      (stages.offsetWidth -
        document.querySelector(".stages-section").offsetWidth) /
      3;

    class Animate {
      constructor(initvalue = 0) {
        this._start = null;
        this._requesId = null;

        this._subscribers = [];
        this._currentValue = initvalue;
        this._oldValue = initvalue;
        this._targetValue = initvalue;
        this._duration = 800;
        this._isIncrement = true;
      }

      animate(value) {
        if (this._requesId) {
          window.cancelAnimationFrame(this._requesId)
        }

        this._start = null;
        this._targetValue = value;
        this._oldValue = this._currentValue;

        this._requesId = window.requestAnimationFrame(this._animate.bind(this));
      }

      _animate(timestamp) {
        if(this._start === null) this._start = timestamp;
        

        const progress = Math.min((timestamp - this._start) / this._duration, 1) * 100;


        this._currentValue = this._oldValue + (((this._targetValue - this._oldValue) / 100) * progress);
        this._emit();

        if(progress < 100) {
          this._requesId = window.requestAnimationFrame(this._animate.bind(this));
        }
      }

      _emit() {
        this._subscribers.forEach((func) => func(this._currentValue));
      }

      on(func) {
        this._subscribers.push(func);
      }

      off(func) {
        const index = this._subscribers.indexOf(func);
        if (index >= 0) {
          this._subscribers.splice(index, 1);
        }
      }
    }


    const inst = new Animate(45);

    inst.on((value) => {
      // counter.innerHTML = value
      positionRocket(value);
    });

    var path = document.querySelector(".rocket-rail");
    var pathLen = path.getTotalLength();
    var rocket = document.querySelector(".rocket");

    function positionRocket(dist) {
      var pos = path.getPointAtLength(dist);

      if (dist + 1 <= pathLen) {
        var posAhead = path.getPointAtLength(dist + 1);
        var angle = Math.atan2(posAhead.y - pos.y, posAhead.x - pos.x);
      } else {
        var posBehind = path.getPointAtLength(dist - 1);
        var angle = Math.atan2(pos.y - posBehind.y, pos.x - posBehind.x);
      }

      rocket.setAttribute(
        "transform",
        "translate(" +
          pos.x +
          "," +
          pos.y +
          ") rotate(" +
          (rad2deg(angle) + 25) +
          ")"
      );
    }

    function rad2deg(rad) {
      return (180 * rad) / Math.PI;
    }

    const stagesMove = (step) => {
      dotsArr.forEach((dot, ind) => {
        dot.style.fill = "#565656";
        if (ind <= step) {
          dot.style.fill = "#5cc3f6";
        }
      });

      $rocket.style.setProperty(
        "--path-offset",
        dotsArr[step].dataset.line + "px"
      );

      if (step <= 2) {
        stages.style.transform = `translateX(0px)`;
      } else if (step >= 3 && step <= 4) {
        stages.style.transform = `translateX(${hideWidth * 1.5 * -1}px)`;
      } else if (step >= 5 && step <= 6) {
        stages.style.transform = `translateX(${hideWidth * 3 * -1}px)`;
      }
    };
    window.addEventListener("resize", () => {
      hideWidth =
        (stages.offsetWidth -
          document.querySelector(".stages-section").offsetWidth) /
        3;
      stagesMove(step);
    });

    stages_next.addEventListener("click", () => {
      step++;
      if (step >= 7) {
        step = 7;
      }
      stagesMove(step);
      inst.animate(rocket_pos[step]);
    });

    stages_prev.addEventListener("click", () => {
      step--;
      if (step <= 0) {
        step = 0;
      }
      stagesMove(step);
      inst.animate(rocket_pos[step]);
    });

    // // stages swipe

    const offset = 60;
    let xDown, yDown;

    document.querySelector(".stages").addEventListener("touchstart", (e) => {
      const firstTouch = getTouch(e);
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    });

    document.querySelector(".stages").addEventListener("touchend", (e) => {
      if (!xDown || !yDown) {
        return;
      }

      const { clientX: xUp, clientY: yUp } = getTouch(e);
      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;
      const xDiffAbs = Math.abs(xDown - xUp);
      const yDiffAbs = Math.abs(yDown - yUp);

      // at least <offset> are a swipe
      if (Math.max(xDiffAbs, yDiffAbs) < offset) {
        return;
      }

      if (xDiffAbs > yDiffAbs) {
        if (xDiff > 0) {
          step++;
          if (step >= 7) {
            step = 7;
          }
          stagesMove(step);
          inst.animate(rocket_pos[step]);
        } else {
          step--;
          if (step <= 0) {
            step = 0;
          }
          stagesMove(step);
          inst.animate(rocket_pos[step]);
        }
      } else {
        return;
      }
    });

    function getTouch(e) {
      return e.changedTouches[0];
    }

    document.querySelectorAll(".rocket-point").forEach((el, i) => {
      el.addEventListener("click", () => {
        step = i;
        stagesMove(step);
        inst.animate(rocket_pos[step]);
      });
    });
    stagesMove(2);
    inst.animate(rocket_pos[2]);
  } catch (e) {
    console.error(e);
  }

  // stagess mob
  try {
    let progress_mob = 0;
    const dotsArr_mob = document.querySelectorAll(".circle-mob");

    window.addEventListener("scroll", () => {
      if (
        window.pageYOffset + 200 >=
          document.querySelector(".stages-section").offsetTop &&
        window.pageYOffset <
          document.querySelector(".stages-section").offsetTop +
            document.querySelector(".stages-section").clientHeight
      ) {
        progress_mob =
          ((window.pageYOffset +
            300 -
            document.querySelector(".stages-section").offsetTop) /
            document.querySelector(".stages-section").clientHeight) *
          100;
        renderRocket2(progress_mob);
      }
      dotsArr_mob.forEach((el, ind) => {
        if (progress_mob >= el.dataset.pos) {
          el.style.fill = "#5cc3f6";
        } else {
          el.style.fill = "#565656";
        }
      });
    });

    const $rocket_mob = document.querySelector(".rocket-svg-mob");
    const rockt = document.querySelector(".rocket-mob");

    function renderRocket2(progress) {
      if (progress >= 77) progress = 77;
      rockt.style.setProperty(
        "transform",
        `translate(0px, ${960 * (progress / 100) - 350 + "px"})`
      );
      $rocket_mob.style.setProperty(
        "--path-offset",
        960 * (1 - progress / 100) + 30 + "px"
      );
    }

    rockt.style.setProperty("transform", `translate(0px, -230px)`);
    $rocket_mob.style.setProperty("--path-offset", "870");
    dotsArr_mob.forEach((el) => (el.style.fill = "#565656"));
  } catch (e) {
    console.error(e);
  }
});
